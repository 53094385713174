import * as React from 'react'
import type { NextPage } from 'next'
import { useTranslations } from 'next-intl'
import { GetStaticPropsContext } from 'next'
import PageLayout from '@/components/layout/PageLayout'
import BookingStepper from '@/components/booking/BookingStepper'

//TODO: later the inex page will serve as a main page replacing thrillparkprague.com
const Home: NextPage = () => {
  const t = useTranslations('Index')

  return (
    <PageLayout title={t('title')} description={t('description')}>
      <BookingStepper product="DUNGEON" />
    </PageLayout>
  )
}

export default Home

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      messages: (await import(`../locales/${locale}.json`)).default,
    },
  }
}
